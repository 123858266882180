// PortalModal.js
import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const PortalModal = ({ visible, onHide }) => {
  const buttonStyle = {
    color: '#fff',
    width: '100%',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center'
  };
  const dialogWidth = window.innerWidth < 768 ? '90vw' : '30vw';

  return (
    <Dialog header="EKŞİOĞLU PORTAL" visible={visible} onHide={onHide} style={{ width: dialogWidth }}>
      <div className="p-d-flex p-flex-column p-ai-center">
        <Button label="MÜVEKKİL GİRİŞİ" style={{ ...buttonStyle, backgroundColor: '#7FB77E' }} onClick={() => window.open('https://www.hasarhukukkurumsal.com/', '_blank')} />
        <Button label="OFİS PERSONELİ GİRİŞİ" style={{ ...buttonStyle, backgroundColor: '#6C4AB6' }} onClick={() => window.open('https://www.hasarhukukkurumsal.com/login', '_blank')} />
        <Button label="SAHA PERSONELİ GİRİŞİ" style={{ ...buttonStyle, backgroundColor: '#EB6440' }} onClick={() => window.open('https://www.hasarhukukkurumsal.com/sahaekibigiris', '_blank')} />
        <Button label="USTA/SERVİS GİRİŞİ" style={{ ...buttonStyle, backgroundColor: '#2F8F9D' }} onClick={() => window.open('https://www.hasarhukukkurumsal.com/ustagiris', '_blank')} />
      </div>
      
    </Dialog>
  );
};

export default PortalModal;